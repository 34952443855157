/** @jsx jsx */
import * as React from "react"
import { jsx, Flex } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Modal from "../components/modal"
import Img from "../components/image"
import { FaFont } from "../components/fa"
import ExternalLink from "../components/external-link"
import { useList } from "../hooks/use-characters"
import { usePlayerCardTool } from "../hooks/use-player-card"

const Page = () => {
	const shie = `fukafukafuka29`
		, href = (folder, name) => (`/images/`+ shie +`/`+ name +`.png`)
	const {t} = useTranslation()
		, list = useList(shie)
		, offset = usePlayerCardTool().find(e => e.id === shie).custom.list
	return (
		<Layout title={t(`Player Card Templates`) +` @`+ t(`Shie`)}>
			<div sx={{mb: [`24px`,4],}}>
				{t(`Author`)}
				<ExternalLink
					sxProp={{ ml: `.5em`, }}
					href={`//twitter.com/`+ shie}
				>
					<FaFont i="twitter" color="link" />@{shie}
				</ExternalLink>
			</div>
			<Flex sx={{flexWrap: `wrap`,}}>
				{list.map((e,i)=>(<React.Fragment key={i}>
					<div sx={{
						width: [`calc(100% / 3)`,`calc(100% / 4)`,`calc(100% / 5)`],
						p: [`6px`, 2],
					}}><Modal
						id={`modal-`+ e}
						label={
							<div sx={{
								position: `absolute`,
								top: 0, left: 0,
								width: `100%`, height: `100%`,
								borderRadius: `50%`,
								overflow: `hidden`,
								zIndex: 1, //iOS対策用
							}}>
								<div sx={{
									position: `absolute`,
									top: `50%`,
									left: offset.some(e2=>e2===e) ? 0 : `unset`,
									right: offset.some(e2=>e2===e) ? `unset` : 0,
									transform: `translateY(-50%)`,
									width: `calc(100% * 16 / 9)`,
									height: `100%`,
								}}>
									<Img folder={shie} name={e} />
								</div>
							</div>
						}
						sxLabel={{
							position: `relative`,
							width: `100%`,
							pt: `100%`,
						}}
						sxContent={{
							p: 0,
							width: [`calc(100% - 2px)`,`640px`,`760px`,`880px`],
						}}
						safety={false}
					>
						<img 
							loading="lazy"
							width="1600" height="900"
							src={href(shie,e)} alt={e}
							sx={{ mb: 0, height: `100%`, }}
						/>
						<div sx={{textAlign: `center`,}}>
							<p><a
								href={href(shie,e)}
								sx={{
									color: `white!important`,
									px: 3,
									border: `solid 1px`,
									borderColor: `second`,
									borderRadius: `.5em`,
								}}
								download={e +`.png`}
							>{t(`Download`)}</a></p>
						</div>
					</Modal></div>
				</React.Fragment>))}
			</Flex>
		</Layout>
	)
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["player-card"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
